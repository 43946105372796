import {props, createAction} from "@ngrx/store";
import {IReferrer} from "../Models/interfaces";
export const enum ReferrerActions {
  UpdateReferrer = '[Referrer] Update Referrer',
  UpdateReferrerSuccess = '[Referrer] Update Referrer Success',
  LookupReferrer = '[Referrer] Lookup Referrer API Call',
  ValidateReferrer =  '[Referrer] Validate Referrer API Call Success',
  ValidateReferrerCompleted =  '[Referrer] Validate Referrer API Call Completed',
};

export const UpdateReferrer = createAction(
  ReferrerActions.UpdateReferrer,
  props<{referrerCode: string}>(),
)

export const LookupReferrer= createAction(
  ReferrerActions.UpdateReferrer,
  props<{referrerCode: string, ip: string}>(),
)

export const UpdateReferrerSuccess = createAction(
  ReferrerActions.UpdateReferrerSuccess,
  props<{referrer: IReferrer}>(),
)


export const ValidateReferrer= createAction(
  ReferrerActions.ValidateReferrer,
  props<{referrerCode: string, ip: string}>(),
)

export const ValidateReferrerCompleted = createAction(
  ReferrerActions.ValidateReferrerCompleted,
  props<{referrer: IReferrer}>(),
)


