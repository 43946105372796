import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { ProductDetailComponent } from './shop/product-detail/product-detail.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import {HttpClientModule} from "@angular/common/http";
import { CartComponent } from './shop/cart/cart.component';
import { HomeComponent } from './home/home.component';
import { CustomerInformationComponent } from './shop/customer-information/customer-information.component';
import {NgrxFormsModule} from "ngrx-forms";
import { ShipMethodComponent } from './shop/ship-method/ship-method.component';
import {FormsModule} from "@angular/forms";
import { PageSpinnerComponent } from './page-spinner/page-spinner.component';
import { ThankyouComponent } from './shop/thankyou/thankyou.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import { HeaderComponent } from './header/header.component';
import { ProductDetailPopsComponent } from './shop/product-detail-pops/product-detail-pops.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ProductDetailComponent,
    CartComponent,
    HomeComponent,
    CustomerInformationComponent,
    ShipMethodComponent,
    PageSpinnerComponent,
    ThankyouComponent,
    HeaderComponent,
    ProductDetailPopsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgrxFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
