import {IReferrer} from "../Models/interfaces";
import {Referrer} from "../Models/Referrer";
import {createReducer, on} from "@ngrx/store";
import {UpdateReferrer, UpdateReferrerSuccess} from "../actions/referrer.actions";
/**
 * Created by nick on 7/9/2019.
 */

export interface IReferrerState {
  referrer: IReferrer
}

export const initialReferrerState:IReferrerState = {
  referrer: new Referrer()
}

export const referrerReducer = createReducer(
  initialReferrerState,
  on(UpdateReferrerSuccess, (state, action) => {
    console.log('Referrer: ', action.referrer);
    return {...state, referrer: action.referrer}
  })
)
