/* not all these fields are needed.  Just a mirror of what is in the API for the requests */
export class OrderDetail {
  itemCode: string;
  quantity: number;
  price: number;
  largeImageName: string;
  shortDetail: string;
  itemDescription: string;
  loyaltyPoints: string;
  parentItemCode: string;
  priceEachOverride: number;
  taxableEachOverride: number;
  shippingPriceEachOverride: number;
  businessVolumeEachOverride: number;
  commissionableVolumeEachOverride:number;
  other1EachOverride: number;
  other2EachOverride: number;
  other3EachOverride: number;
  other4EachOverride:number;
  other5EachOverride: number;
  other6EachOverride: number;
  other7EachOverride: number;
  other8EachOverride: number;
  other9EachOverride: number;
  other10EachOverride: number;
  descriptionOverride: string;
  priceAutoship: number;
  priceMember: number;
  priceRetail: number;
  priceTotal: number;
  onAutoship: boolean;
}
