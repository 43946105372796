import {Component, OnInit} from '@angular/core';
import {Store, select} from "@ngrx/store";
import {IState, selectSelectedItemObject, selectItemQuantity, selectAutoOrderQuantity} from "./reducers/index";
import {Observable} from "rxjs";
import {combineLatest} from "rxjs";
import {IItemObject} from "./Models/interfaces";
import {OrderCalculationRequest} from "./Models/OrderCalculationRequest";
import {OrderDetail} from "./Models/OrderDetail";
import {OrderActions} from "./actions/order.actions";
import {Order} from "./Models/Order";
import {ReferrerActions} from "./actions/referrer.actions";
import {FORM_ID} from "./reducers/customerState";
import * as _ from 'underscore';
import {NavigateExternal, AppActions} from "./shop/app.actions";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit  {

  selectedItem$: Observable<IItemObject>;
  itemQuantity$: Observable<number>;
  autoOrderQuantity$: Observable<number>;
  billingState$: Observable<string>;
  selectedShipMethodId$: Observable<number>;
  submitting$: Observable<boolean>;
  title = 'EvolvSmallSite';
  displayModal: boolean;
  couponCode: string;
  couponErrorString: string;

  subdomain: string;
  domain: string;

  ngOnInit() {
    let product:any = this.getProduct();
    this.store.dispatch({type: '[Item] Load ItemCode', itemCode: product.itemCode, kit: product.kit})
    this.checkReferrer();
    this.displayModal = this.getIfSubdomainNeeded();

    this.route.queryParams

      .subscribe(params => {
        console.log(params); // {order: "popular"}

        if (params.err === "1") {
          this.couponErrorString = "Invalid Order Code";
        }

      });

  }

  checkReferrer() {
    this.store.dispatch( {type: ReferrerActions.UpdateReferrer, referrerCode:this.getSubdomain() })
  }

  getProduct() {
    let domain = window.location.hostname;
    console.log('DOMAIN: ', domain);

    // if (domain==='localhost') domain='ff4.entourage.myevolv.com'
    //if (domain==='localhost') return { itemCode:'GRP_RML-TRX2', kit: true}
    if (domain==='localhost') return { itemCode:'GRP_HopePopsCases_US', kit: true};
    console.log('SPLIT: ', domain.split('.')[1]);
    
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      return { itemCode:'GRP_RML-TRX2', kit: true}
    } else {
      // These are the product cases
      if (domain.split('.')[1] ==='hopepopssite') {
        return {itemCode: 'GRP_HopePopsCases_US', kit: false }
      }
      if (domain.split('.')[1] ==='entourage') {
        return {itemCode: 'Entourage-1oz', kit: false }
      }
      if (domain.split('.')[1] ==='entourage2') {
        return {itemCode: 'Entourage-1', kit: false }
      }
      if (domain.split('.')[1] ==='hopepops') {
        return {itemCode: 'CherryPop-1', kit: false }
      }
      if (domain.split('.')[1] ==='ease') {
        return {itemCode: 'Ease-1', kit: false }
      }
      //return  domain.split('.')[0];
    }
    return { itemCode:'GRP_RML-TRX2', kit: true}
  }

  submitCoupon() {
    if (this.couponCode === undefined || this.couponCode === null) return;

/*    const domain = window.location.hostname;
    console.log(domain);
    var newURL = "";
    if (domain === 'localhost') {
      newURL = 'https://'+ this.couponCode + '.hopepops.myevolv.com';

    } else {

      newURL = 'https://'+ this.couponCode + '.' + domain;
    }
    console.log('Redirecting');*/
    this.store.dispatch({type: ReferrerActions.ValidateReferrer, referrerCode: this.couponCode, ip: undefined});
   // this.store.dispatch({type: AppActions.NavigateExternal, path: newURL});
  }
  getIfSubdomainNeeded() {
    const domain = window.location.hostname;


    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      return  true;
    } else {
      return  false;
    }
  }
  getSubdomain() {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      return  'ff4';
    } else {
      return  domain.split('.')[0];
    }
  }

  constructor(private store: Store<IState>, private route: ActivatedRoute) {;
    this.subdomain = this.getSubdomain();
    this.domain = window.location.hostname;
    this.selectedItem$ = store.select(selectSelectedItemObject);
    this.itemQuantity$ = store.select(selectItemQuantity);
    this.autoOrderQuantity$ = store.select(selectAutoOrderQuantity);
    this.billingState$ = store.select( s=> s.customerState.customerForm.value.mainState);
    this.selectedShipMethodId$ = store.select(s=>s.orderState.selectedShipMethodID);

    this.submitting$ = store.select( s=> s.orderState.submittingEnrollment);

    const combined = combineLatest(this.selectedItem$, this.itemQuantity$, this.autoOrderQuantity$,
      this.billingState$, this.selectedShipMethodId$);
    combined.subscribe(
      ([item, quantity, autoshipQuantity,billingState,selectedShipMethodId]) => {
        try {
          console.log('ITEM: ', item);
          console.log('QUANTITY: ', quantity);
          console.log('AutoshipQuantity: ', autoshipQuantity);
          if (quantity > 0) {
            const request = new OrderCalculationRequest();
            const detail = new OrderDetail();
            detail.itemCode = item.itemCode;
            detail.quantity = quantity;
            detail.price = item.priceEach;
            detail.priceAutoship = item.priceAutoship;
            detail.priceMember = item.priceMember;
            detail.priceRetail = item.priceRetail;
            detail.onAutoship = autoshipQuantity > 0;
            if (autoshipQuantity > 0) {
              detail.priceEachOverride = item.priceAutoship;
            }
            if (selectedShipMethodId >0) {
              request.shipMethodID = selectedShipMethodId;
            }
            request.details = new Array<OrderDetail>();
            request.details.push(detail);
            request.state = billingState !== '' ? billingState : request.state;

            this.store.dispatch({type: OrderActions.CalculateOrder, order: request})
          }
          if (quantity == 0) {
            this.store.dispatch({type: OrderActions.CalculateOrderCompleted, order: new Order()})
          }
          if (autoshipQuantity > 0) {
            const request = new OrderCalculationRequest();
            const detail = new OrderDetail();
            detail.itemCode = item.itemCode;
            detail.quantity = quantity;
            detail.price = item.priceEach;
            detail.priceAutoship = item.priceAutoship;
            detail.priceMember = item.priceMember;
            detail.priceRetail = item.priceRetail;
            detail.priceEachOverride = item.priceAutoship;
            request.details = new Array<OrderDetail>();
            request.details.push(detail);

            this.store.dispatch({type: OrderActions.CalculateAutoship, order: request})
          }
          if (autoshipQuantity == 0) {
            this.store.dispatch({type: OrderActions.CalculateAutoshipCompleted, order: new Order()})
          }
        } catch (error) {
          console.log('Error creating calculation request: ',error);
        }

      }

    )
  //  this.setupDebugAccountInformation()
  }

  setupDebugAccountInformation() {
    let debugInformation = [
      { name: 'firstName', value: 'Nick'},
      { name: 'lastName', value: 'Nafpliotis'},
      { name: 'email', value: 'nick@pennant.com'},
      { name: 'confirmEmail', value: 'nick@pennant.com'},
      { name: 'mailAddress1', value: 'Testing 1'},
      { name: 'mailAddress2', value: 'Address Test 1'},
      { name: 'mailCity', value: 'Lincolnshire'},
      { name: 'mailState', value: 'IL'},
      { name: 'mailCountry', value: 'US'},
      { name: 'mailZip', value: '60069'},
      { name: 'mainAddress1', value: 'Testing 2'},
      { name: 'mainAddress2', value: 'Address Test 2'},
      { name: 'mainCity', value: 'Gurnee'},
      { name: 'mainState', value: 'IL'},
      { name: 'mainCountry', value: 'US'},
      { name: 'mainZip', value: '60031'},
      { name: 'ccNumber', value: '9696'},
      { name: 'ccType', value: '1'},
      { name: 'ccName', value: 'Nick Nafpliotis'},
      { name: 'ccCVV', value: '453'},
      { name: 'ccExpireMonth', value: '4'},
      { name: 'ccExpireYear', value: '2025'},
   //   { name: 'noClaims', value: true },
      { name: 'policiesAgreement', value: true},
    ];

    _.forEach(debugInformation, (x)=> {
      this.store.dispatch(
        {
          controlId: FORM_ID+"."+x.name,
          type: 'ngrx/forms/SET_VALUE',
          value: x.value
        }
      );
    });

    this.store.dispatch(
      {
        controlId: FORM_ID+".firstName",
        type: 'ngrx/forms/SET_VALUE',
        value: 'Nick'
      }
    );
  }
}
