import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {FormGroupState} from "ngrx-forms";
import {ICustomer} from "../../Models/interfaces";
import {IState, selectSelectedItemObject} from "../../reducers/index";
import {Store, select} from "@ngrx/store";
import {take} from "rxjs/internal/operators";
import {Customer} from "../../Models/Customer";
import * as _ from 'underscore';
import {FORM_ID} from "../../reducers/customerState";
import {EnrollCustomerRequest} from "../../Models/EnrollCustomerRequest";
import {OrderDetailRequest} from "../../Models/OrderDetailRequest";
import {CustomerActions} from "../../actions/customer.actions";
import {OrderActions} from "../../actions/order.actions";
import {NotificationActions} from "../../actions/notification.action";
import {Router} from "@angular/router";

@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss']
})
export class CustomerInformationComponent implements OnInit {
  formState$: Observable<FormGroupState<ICustomer>>;
  state$: Observable<any>;
  public cherryPopItem: boolean;


  constructor(private store: Store<IState>, private router: Router) {
    this.formState$ = this.store.select(s => s.customerState.customerForm);

    store.pipe(select(selectSelectedItemObject))
      .subscribe((data) => {
        if (data.itemCode === 'CherryPop-1') {
          this.cherryPopItem = true;
        } else {
          this.cherryPopItem = false;
        }
      });
  }

  ngOnInit() {
  }

  submitOrder() {
    let cust = new Customer();
    let properties = Object.getOwnPropertyNames(cust);
    _.each( properties,(value) => {
      this.store.dispatch(
        {
          controlId: FORM_ID+"."+value,
          type: 'ngrx/forms/MARK_AS_TOUCHED'
        }
      );
    });

    this.state$ = this.store.select( s=>s) ;
    const theData = this.state$.pipe(take(1));
    theData.subscribe( data => {
      console.log(data);
      if (data.itemState.quantity == 0) {
        this.store.dispatch({type: NotificationActions.SendErrorNotification, message: "You must order at least 1 to continue"});
        return;
      }
      if (data.orderState.selectedShipMethodID == 0) {
        this.store.dispatch({type: NotificationActions.SendErrorNotification, message: "You must choose a shipping method"});
        return;
      }
      if (data.customerState.customerForm.isValid) {
        console.log('FORM IS VALID');
        let c = Object.assign(cust, data.customerState.customerForm.value);
        let request = new EnrollCustomerRequest();
        request.setCustomerInformation(c);
        let detail = new OrderDetailRequest();
        detail.itemCode = data.itemState.itemCode;
        detail.quantity = data.itemState.quantity;
        request.orderDetails = [detail];
        request.priceTypeID = 2;
        request.shipMethodID = data.orderState.selectedShipMethodID;
        if (data.itemState.autoOrderQuantity >0) {
          let autoshipDetail = new OrderDetailRequest();
          autoshipDetail.itemCode = detail.itemCode;
          autoshipDetail.quantity = data.itemState.autoOrderQuantity;
          request.autoOrderDetails = [autoshipDetail];

        }
        if (this.cherryPopItem) {
            request.mainAddress1 = "1551 Corporate Dr Ste 200";
            request.mainCity = "Irving";
            request.mainState = "TX";
            request.mainZip = "75038";
            request.mainCountry = "US";
        } else {
          if (data.customerState.customerForm.value.shippingBillingSame) {
            request.mailAddress1 = request.mainAddress1;
            request.mailAddress2 = request.mainAddress2;
            request.mailCity = request.mainCity;
            request.mailZip = request.mainZip;
            request.mailCountry = request.mainCountry;
            request.mailState = request.mainState;
          }
        }
        request.customerTypeID = 2;
        request.generateLogin = true;
       //  request.souce='Single Item Website';
        console.log('FINAL REQUEST: ',JSON.stringify(request));
        //request.generate
        this.store.dispatch({type: OrderActions.SubmittingEnrollment});
        this.store.dispatch({type: CustomerActions.EnrollCustomer,  enrollmentRequest: request});
      };
      if (data.customerState.customerForm.isInvalid) {
        console.log('FORM IS INVALID');
      };
    })

  }
}
