/**
 * Created by nick on 7/10/2019.
 */
import {createReducer, on, createSelector} from "@ngrx/store";
import {UpdateReferrer, UpdateReferrerSuccess} from "../actions/referrer.actions";
import {IOrder} from "../Models/interfaces";
import {Order} from "../Models/Order";
import {
  CalculateOrderCompleted,
  CalculateAutoshipCompleted,
  ShipMethodsLoaded,
  UpdateSelectedShipMethod, CalculateOrderStart, CalculatingOrderEnd, SubmittingEnrollment, SubmittingEnrollmentCompleted
} from "../actions/order.actions";
import {IState} from "./index";
/**
 * Created by nick on 7/9/2019.
 */

export const selectOrderState = (state: IState) => state.orderState;

export const selectCalculatedOrder = createSelector(
  selectOrderState,
  (state: IOrderState) => state.calculatedOrder
);
export const selectCalculatedAutoship = createSelector(
  selectOrderState,
  (state: IOrderState) => state.calculatedAutoship
);
export const selectShipMethods = createSelector(
  selectOrderState,
  (state: IOrderState) => state.shipMethods
);
export const selectSelectedShipMethodId = createSelector(
  selectOrderState,
  (state: IOrderState) => state.selectedShipMethodID
);



export interface IShipMethod {
  shipMethodID: number,
  description: string,
  shippingAmount: number
}


export interface IOrderState {
  calculatedOrder: IOrder;
  calculatedAutoship: IOrder;
  shipMethods: Array<IShipMethod>;
  order: IOrder;
  selectedShipMethodID: number;
  submittingEnrollment: boolean;
  calculatingOrder: boolean;
}

export const initialOrderState:IOrderState = {
  calculatedOrder: new Order(),
  calculatedAutoship: new Order(),
  shipMethods: new Array<IShipMethod>(),
  order: new Order(),
  selectedShipMethodID: 0,
  submittingEnrollment: false,
  calculatingOrder: false,
}

export const orderReducer = createReducer(
  initialOrderState,
  on(CalculateOrderCompleted, (state, action) => {
    return {...state, calculatedOrder: action.order}
  }),
  on(CalculateAutoshipCompleted, (state, action) => {
    return {...state, calculatedAutoship: action.order}
  }),
  on(ShipMethodsLoaded, (state, action) => {
    return {...state, shipMethods: action.shipMethods}
  }),
  on(UpdateSelectedShipMethod, (state, action) => {
    return {...state, selectedShipMethodID: action.shipMethodID}
  }),
  on(CalculateOrderStart, (state, action) => {
    return {...state, calculatingOrder: true}
  }),
  on(CalculatingOrderEnd, (state, action) => {
    return {...state, calculatingOrder: false}
  }),
  on(SubmittingEnrollment, (state, action) => {
    return {...state, submittingEnrollment: true}
  }),
  on(SubmittingEnrollmentCompleted, (state, action) => {
    console.log('Marking Completed');
    return {...state, submittingEnrollment: false}
  }),
)
