
import {props, createAction} from "@ngrx/store";
import {IItemObject} from "../Models/interfaces";
import {NavigationExtras} from "@angular/router";
export const enum ItemActions {
  DecrementQuantity = '[Item] Decrement',
  IncrementQuantity = '[Item] Increment',
  DecrementAutoshipQuantity = '[Item] Autoship Decrement',
  IncrementAutoshipQuantity = '[Item] Autoship Increment',
  UpdateSelectedItem  = '[Item] Update Selected Item',
  RouterGo = '[Router] Go',
  LoadItem = '[Item] Load ItemCode',
};

export const LoadItem = createAction(
  ItemActions.LoadItem,
  props<{itemCode: string, kit: boolean}>(),
)
export const IncrementQuantity = createAction(
  ItemActions.IncrementQuantity
);

export const DecrementQuantity = createAction(
  ItemActions.DecrementQuantity
);

export const IncrementAutoshipQuantity = createAction(
  ItemActions.IncrementAutoshipQuantity
);

export const DecrementAutoshipQuantity = createAction(
  ItemActions.DecrementAutoshipQuantity
);

export const UpdateSelectedItem = createAction(
  ItemActions.UpdateSelectedItem,
  props<{selectedItem: IItemObject}>(),
);

export const  RouterGo  = createAction(
  ItemActions.RouterGo,
  props< {path: any[],
    queryParams?: any,
    extras?: NavigationExtras;}>(),
);


