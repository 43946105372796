/* not all these fields are needed.  Just a mirror of what is in the API for the requests */
import {OrderDetail} from "./OrderDetail";

export class OrderCalculationRequest {
  public details: OrderDetail[];
  public customerID: number;
  public warehouseID: number;
  public shipMethodID: number;
  public priceTypeID: number;
  public overrideShipping: string;
  public shippingAmountOverride: number;
  public address1: string;
  public address2: string;
  public city: string;
  public country: string;
  public state: string;
  public zip: string;
  public dropShipID: number;
  public couponCode: string;
  public other17: string;
  public dropShipDetails: OrderDetail[];

  constructor() {
    this.priceTypeID = 2;
    this.warehouseID = 167;
    this.shipMethodID = 267;
    this.state = 'TX';
    this.zip = '75244';
    this.country = 'US';
    this.other17 = 'Enrollment'
  }
}

/*


 */
