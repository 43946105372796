import { Component, OnInit } from '@angular/core';
import {IState, selectAutoOrderQuantity, selectItemQuantity, selectSelectedItemObject} from "../../reducers";
import {IItemObject} from "../../Models/interfaces";
import {Observable} from "rxjs/index";
import {State, Store} from "@ngrx/store";
import {IShipMethod, selectShipMethods} from "../../reducers/orderReducer";
import {OrderActions} from "../../actions/order.actions";

@Component({
  selector: 'app-ship-method',
  templateUrl: './ship-method.component.html',
  styleUrls: ['./ship-method.component.scss']
})
export class ShipMethodComponent implements OnInit {

  selectedItem$: Observable<IItemObject>;
  itemQuantity$: Observable<number>;
  autoOrderQuantity$: Observable<number>;
  billingState$: Observable<string>;
  shipMethods$: Observable<IShipMethod[]>;
  selectedShipMethodId$: Observable<number>;
  currentShipMethodId:number;

  constructor(private store: Store<IState>) {
    this.currentShipMethodId = 0;
    this.selectedItem$ = store.select(selectSelectedItemObject);
    this.itemQuantity$ = store.select(selectItemQuantity);
    this.autoOrderQuantity$ = store.select(selectAutoOrderQuantity);
    this.billingState$ = store.select( s=> s.customerState.customerForm.value.mainState);
    this.shipMethods$ = store.select(selectShipMethods);
    this.selectedShipMethodId$ = store.select(s=>s.orderState.selectedShipMethodID);
    this.selectedShipMethodId$.subscribe( (data) => {
      this.currentShipMethodId = data;
    });
  }

  onChange(value) {
    this.store.dispatch({type: OrderActions.UpdateSelectedShipMethod, shipMethodID: value});
  }

  ngOnInit() {
  }

}
