import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {Store, select} from "@ngrx/store";
import {IState, selectSelectedItemObject} from "../reducers/index";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public cherryPopItem: boolean;

  constructor(private store: Store<IState>) {

    store.pipe(select(selectSelectedItemObject))
      .subscribe( (data) => {
        if (data.itemCode === 'CherryPop-1') {
          this.cherryPopItem = true;
        } else {
          this.cherryPopItem = false;
        }
      });

  }

  ngOnInit() {
  }

}
