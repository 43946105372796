import {IGroupMember, IItemObject} from "./interfaces";


export class ItemObject implements IItemObject {
  addValue: number;
  allowOnAutoOrder: boolean;
  availableBasic: boolean;
  availableSilverGold: boolean;
  field1: string;
  field10: string;
  field2: string;
  field3: string;
  field4: string;
  field5: string;
  field6: string;
  field7: string;
  field8: string;
  field9: string;
  groupDescription: string;
  groupMembers: IItemObject[];
  groupMembersDescription: string;
  hideFromSearch: boolean;
  isGroupMaster: boolean;
  isItemChoiceMaster: boolean;
  isVirtual: boolean;
  itemCode: string;
  itemDescription: string;
  itemID: number;
  largeImageName: string;
  longDetail: string;
  longDetail2: string;
  longDetail3: string;
  longDetail4: string;
  memberDescription: any;
  otherCheck1: boolean;
  otherCheck2: boolean;
  otherCheck3: boolean;
  otherCheck4: boolean;
  otherCheck5: boolean;
  priceAutoship: number;
  priceEach: number;
  priceEnroll: number;
  priceMember: number;
  priceRetail: number;
  pv: number;
  quantity: number;
  qv: number;
  shortDetail: string;
  shortDetail2: string;
  shortDetail3: string;
  shortDetail4: string;
  sortOrder: number;
  unavailableCustomers: boolean;
  unavailableMembers: boolean;

}
