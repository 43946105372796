import { Injectable } from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
import {map, mergeMap} from "rxjs/operators";
import {ItemService} from "./services/item.service";
import {LoadItemCode, NoopAction, AppActions, NavigateExternal} from "./shop/app.actions"
import {switchMap, catchError, tap} from "rxjs/internal/operators";
import {EMPTY, of, Observable, from} from "rxjs";
import {ReferrerActions} from "./actions/referrer.actions";
import {CustomerService} from "./services/customer.service";
import {OrderActions, SubmittingEnrollmentCompleted} from "./actions/order.actions";
import {OrderCalculationRequest} from "./Models/OrderCalculationRequest";
import {UpdateCustomerFormValue, CustomerActions} from "./actions/customer.actions";
import {FORM_ID} from "./reducers/customerState";
import * as _ from 'underscore';
import {IOrder} from "./Models/interfaces";
import {IState} from "./reducers/index";
import {Store} from "@ngrx/store";
import {Order} from "./Models/Order";
import {NotificationActions} from "./actions/notification.action";
import {ToastrService} from "ngx-toastr";
import {ItemActions} from "./actions/item.actions";
import {Router} from "@angular/router";

@Injectable()
export class AppEffects {

  loadItems$ = createEffect(() => this.action$.pipe(
    ofType(ItemActions.LoadItem),
    mergeMap( (payload:any)=>  this.itemService.getItem(payload.itemCode)
      .pipe(
        map(item => ({ type: '[Item] ItemCode Loaded Success', item: item, kit: payload.kit })),
        catchError(()=>EMPTY)
      ))));

  validateReferrer = createEffect(() => this.action$.pipe(
    ofType(ReferrerActions.ValidateReferrer),
    mergeMap( (payload:any)=>  this.customerService.checkReferrer(payload.referrerCode, undefined)
      .pipe(
        map(result => ({ type: ReferrerActions.ValidateReferrerCompleted, referrer: result })),
        catchError(()=>EMPTY)
      ))));



  loadReferrerStepOne$ = createEffect( () => this.action$.pipe(
    ofType(ReferrerActions.UpdateReferrer),
    map( (payload:any) => ({ type: ReferrerActions.LookupReferrer, referrerCode: payload.referrerCode, ip: undefined })),
        catchError(()=>EMPTY)
      ));

  callReferrerLookupAPI$ = createEffect( () => this.action$.pipe(
    ofType(ReferrerActions.LookupReferrer),
    mergeMap( (payload:any)=>  this.customerService.checkReferrer(payload.referrerCode, payload.ip)
      .pipe(
        map(item => ({ type: ReferrerActions.UpdateReferrerSuccess, referrer: item })),
        catchError(()=>EMPTY)
      ))));

  enrollCustomer$ =  createEffect( () => this.action$.pipe(
    ofType(CustomerActions.EnrollCustomer),
    mergeMap( (payload:any)=>  {
      console.log('Enrollment PAYLOAD: ', payload);
      this.store.dispatch({type: OrderActions.SubmittingEnrollment});
      return this.customerService.enrollCustomer(payload.enrollmentRequest)
        .pipe(
          switchMap((response) => [
            { type: CustomerActions.EnrollmentSuccess, enrollmentSuccess: response },
            { type: OrderActions.SubmittingEnrollmentCompleted},
            { type: ItemActions.RouterGo, path: '/thankyou'}
          ]),
          catchError((response:any)=> {
            console.log('ERROR: ', response['error']);
            return [
              { type: OrderActions.SubmittingEnrollmentCompleted},
              { type: NotificationActions.SendErrorNotification, message: response['error']},
             /* { type: CustomerActions.EnrollmentSuccess, enrollmentSuccess: this.getTestResult() },  // For Testing
              { type: ItemActions.RouterGo, path: '/thankyou'} // For Testing*/
            ]
          })
        )
    })));

  validateReferrerCompleted = createEffect(() => this.action$.pipe(
    ofType(ReferrerActions.ValidateReferrerCompleted),
    mergeMap( (payload:any)=>  {
      const domain = window.location.hostname;
       if (payload.referrer !== null) {
         var webAlias = payload.referrer.webAlias;
         console.log('Result: '+ JSON.stringify(payload));

         console.log(domain);
         var newURL = "";
         if (domain === 'localhost') {
           newURL = 'https://'+ webAlias + '.hopepops.myevolv.com';

         } else {

           newURL = 'https://'+ webAlias + '.' + domain;
         }
         console.log('Redirecting');
         this.store.dispatch({type: AppActions.NavigateExternal, path: newURL});
       } else {
         this.store.dispatch({type: AppActions.NavigateExternal, path: window.location.href+"?err=1"});
       }
      //window.location.href = payload.path;
      return of({type: AppActions.Noop});
    })));


  externalNavigate$ = createEffect( () => this.action$.pipe(
    ofType(NavigateExternal),
    mergeMap( (payload:any)=>  {
      // console.log('Redirecting to: '+payload.path);
      window.location.href = payload.path;
      return of({type: AppActions.Noop});
    })));

  navigate$ = createEffect( () => this.action$.pipe(
    ofType('[Router] Go'),
    mergeMap( (payload:any)=>  {
      this.router.navigate([payload.path]);
      return of({type: AppActions.Noop});
    })));

  calculateOrder$ = createEffect( () => this.action$.pipe(
    ofType(OrderActions.CalculateOrder),
    mergeMap( (payload:any)=>  {
    //  console.log('CALcuLATE PAYLOAD: ', payload.order);
      this.store.dispatch({type: OrderActions.CalculateOrderStart});
      return this.itemService.calculateOrder(payload.order)
        .pipe(
          switchMap((order:IOrder) => [
                { type: OrderActions.CalculateOrderCompleted, order: order },
                { type: OrderActions.ShipMethodsLoaded, shipMethods: order.shipMethods },
                { type: OrderActions.CalculatingOrderEnd },
            ]),
          catchError(()=> [
            { type: OrderActions.CalculateOrderCompleted, order: new Order() },
            { type: OrderActions.ShipMethodsLoaded, shipMethods: null },
            { type: OrderActions.CalculatingOrderEnd },
          ])
        )
    })));

  shippingBillingEffect$ = createEffect( () => this.action$.pipe(
    ofType(UpdateCustomerFormValue),
    mergeMap( (payload:any)=>  {

      console.log('Form Update', payload);
/*      return this.itemService.calculateOrder(payload.order)
        .pipe(
          map(order => ({ type: OrderActions.CalculateOrderCompleted, order: order })),
          catchError(()=>EMPTY)
        );*/
        if (payload.controlId ==  FORM_ID+".shippingBillingSame") {
          const formControls = [
            'mailAddress1',
            'mailAddress2',
            'mailCity',
            'mailState',
            'mailZip',
            'mailCountry',
          ];
          if (payload.value == true) {
            let actionArray=[];
            _.forEach(formControls, (value) => {
              actionArray.push(
                {controlId: FORM_ID + '.' + value, type: "ngrx/forms/DISABLE"}
              );
            });
            return from(
              actionArray
            );

          } else if (payload.value == false) {
            let actionArray=[];
            _.forEach(formControls, (value) => {
              actionArray.push(
                {controlId: FORM_ID + '.' + value, type: "ngrx/forms/ENABLE"}
              );
            });
            return from(
              actionArray
            );
          }
        }

          return of({type: AppActions.Noop});
    })));

  calculateAutoship = createEffect( () => this.action$.pipe(
    ofType(OrderActions.CalculateAutoship),
    mergeMap( (payload:any)=>  {
      console.log('CALcuLATE PAYLOAD: ', payload.order);
      return this.itemService.calculateOrder(payload.order)
        .pipe(
          map(order => ({ type: OrderActions.CalculateAutoshipCompleted, order: order })),
          catchError(()=>EMPTY)
        )
    })));

  errorNotification$ = createEffect( () => this.action$.pipe(
    ofType(NotificationActions.SendErrorNotification),
    mergeMap( (payload:any)=>  {
      this.toastr.error(payload.message, 'Error');
      return of({type: AppActions.Noop});
    })));

  infoNotification$ = createEffect( () => this.action$.pipe(
    ofType(NotificationActions.SendInformationNotification),
    mergeMap( (payload:any)=>  {
      this.toastr.info(payload.message, 'Error');
      return of({type: AppActions.Noop});
    })));


constructor(private store: Store<IState>, private action$: Actions, private itemService: ItemService,
  private customerService: CustomerService,
            private toastr: ToastrService, private router: Router) {}
}
