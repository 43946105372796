/**
 * Created by nick on 7/12/2019.
 */
export class OrderDetailRequest {
  itemCode: string;
  quantity: number;
  priceEachOverride: number;
  parentItemCode: string;
  taxableEachOverride: number;
  shippingPriceEachOverride: number;
  businessVolumeEachOverride: number;
  commissionableVolumeEachOverride:number;
  other1EachOverride: number;
  other2EachOverride: number;
  other3EachOverride: number;
  other4EachOverride:number;
  other5EachOverride: number;
  other6EachOverride: number;
  other7EachOverride: number;
  other8EachOverride: number;
  other9EachOverride: number;
  other10EachOverride: number;
  descriptionOverride: string;
  reference1: string;
}
