import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProductDetailComponent} from "./shop/product-detail/product-detail.component";
import {HomeComponent} from "./home/home.component";
import {ThankyouComponent} from "./shop/thankyou/thankyou.component";


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'thankyou', component: ThankyouComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
