/**
 * Created by nick on 7/10/2019.
 */
/**
 * Created by nick on 7/9/2019.
 */

import {props, createAction} from "@ngrx/store";
import {ICustomer} from "../Models/interfaces";
import {EnrollCustomerRequest} from "../Models/EnrollCustomerRequest";
import {EnrollCustomerResponse} from "../Models/EnrollCustomerResponse";
import {EnrollmentSuccessful} from "../Models/EnrollmentSuccessful";
export const enum CustomerActions {
  UpdateCustomer = '[Customer] Update',
  EnrollCustomer = '[Customer] Enroll',
  EnrollCustomerCompleted = '[Customer] Enrollment Completed',
  EnrollmentSuccess = '[Customer] Enrollment Success'
};

export const UpdateCustomer = createAction(
  CustomerActions.UpdateCustomer,
  props<{customer: ICustomer}>(),
);

export const UpdateCustomerFormValue = createAction(
  'ngrx/forms/SET_VALUE',
  props<{controlId: string,value:boolean}>(),
);

export const EnrollCustomer = createAction(
  CustomerActions.EnrollCustomer,
  props<{enrollmentRequest: EnrollCustomerRequest}>(),
);

export const EnrollCustomerCompleted = createAction(
  CustomerActions.EnrollCustomerCompleted,
  props<{enrollmentResponse: EnrollCustomerResponse}>(),
);

export const EnrollmentSuccess = createAction(
  CustomerActions.EnrollmentSuccess,
  props<{enrollmentSuccess: EnrollmentSuccessful}>(),
);



