/**
 * Created by nick on 7/9/2019.
 */
import {props, createAction} from "@ngrx/store";
import {IOrder, ICustomer} from "../Models/interfaces";
import {OrderCalculationRequest} from "../Models/OrderCalculationRequest";
import {IShipMethod} from "../reducers/orderReducer";
export const enum OrderActions {
  CalculateOrder = '[Order] Calculate',
  CalculateOrderCompleted = '[Order] Calculate Completed',
  CalculateAutoship = '[Order] Calculate Autoship',
  CalculateAutoshipCompleted = '[Order] Calculate Autoship Completed',
  ShipMethodsLoaded = '[ShippingMethods] Shipping Methods Loaded',
  UpdateSelectedShipMethod = '[ShippingMethods] Select Ship Method',
  CalculateOrderStart = '[Order] Start Calculating Order',
  CalculatingOrderEnd = '[Order] Stop Calculating Order',
  SubmittingEnrollment = '[Order] Submitting Enrollment',
  SubmittingEnrollmentCompleted = '[Order] Submitting Enrollment Completed',
};

export const CalculateOrder = createAction(
  OrderActions.CalculateOrder,
  props<{order: OrderCalculationRequest}>(),
);

export const SubmittingEnrollment = createAction(
  OrderActions.SubmittingEnrollment,
);

export const SubmittingEnrollmentCompleted = createAction(
  OrderActions.SubmittingEnrollmentCompleted,
);

export const CalculateOrderCompleted = createAction(
  OrderActions.CalculateOrderCompleted,
  props<{order: IOrder}>(),
);

export const CalculateAutoship = createAction(
  OrderActions.CalculateAutoship,
  props<{order: OrderCalculationRequest}>(),
);

export const CalculateAutoshipCompleted = createAction(
  OrderActions.CalculateAutoshipCompleted,
  props<{order: IOrder}>(),
);

export const ShipMethodsLoaded = createAction(
  OrderActions.ShipMethodsLoaded,
  props<{shipMethods: Array<IShipMethod>}>(),
);

export const UpdateSelectedShipMethod = createAction(
  OrderActions.UpdateSelectedShipMethod,
  props<{shipMethodID: number}>(),
);

export const CalculateOrderStart = createAction(
  OrderActions.CalculateOrderStart,
);

export const CalculatingOrderEnd = createAction(
  OrderActions.CalculatingOrderEnd,
);



