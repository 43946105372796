import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {ItemObject} from "../Models/ItemObject";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {OrderCalculationRequest} from "../Models/OrderCalculationRequest";

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(private http: HttpClient) {

  }

  getItem(itemCode: string): Observable<ItemObject> {
    console.log('Inside Get Item:', itemCode);
    return this.http.post<ItemObject>(environment.API_ROOT+'/Items/single',
      {
        "LanguageID": 1,
        "ItemCode": itemCode,
        "WarehouseID": 167,
        "PriceTypeID": 2,
        "CurrencyCode": "usd",
        "Country": "US"
      });
  }

  calculateOrder(order: OrderCalculationRequest) {
    return this.http.post(environment.API_ROOT + '/Orders/Calculate', order);
  }

/*  calculateTotals() {
    let testData = {
      "Details": [{
        "itemCode": "Kit-RML-TRV1",
        "quantity": 1,
        "price": 343.99,
        "largeImageName": "19_0610_TotalReboot_Cart_Image_CR.jpg",
        "shortDetail": "",
        "itemDescription": "Total Reboot with Vanilla shakes",
        "loyaltyPoints": "",
        "parentItemCode": "",
        "priceEachOverride": null,
        "taxableEachOverride": null,
        "shippingPriceEachOverride": null,
        "businessVolumeEachOverride": null,
        "commissionableVolumeEachOverride": null,
        "other1EachOverride": null,
        "other2EachOverride": null,
        "other3EachOverride": null,
        "other4EachOverride": null,
        "other5EachOverride": null,
        "other6EachOverride": null,
        "other7EachOverride": null,
        "other8EachOverride": null,
        "other9EachOverride": null,
        "other10EachOverride": null,
        "descriptionOverride": "",
        "priceAutoship": 324.990001,
        "priceMember": 343.99,
        "priceRetail": 382.99,
        "priceTotal": "",
        "onAutoship": false,
        "$$hashKey": "object:456"
      }],
      "PriceTypeID": 2,
      "WarehouseID": 167,
      "ShipMethodID": 267,
      "Address1": "",
      "Address2": "",
      "City": "",
      "State": "TX",
      "Zip": "75244",
      "Country": "US",
      "CouponCode": "hope25",
      "Other17": "Enrollment"
    }

    return this.http.post(environment.API_ROOT + 'Orders/Calculate', sendingObject);

  }*/

  /*



   https://evolvcorpapi.azurewebsites.net/Orders/Calculate


   */

}
