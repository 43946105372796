import {IOrder, IOrderDetails} from "./interfaces";
import {OrderDetail} from "./OrderDetail";
import {IShipMethod} from "../reducers/orderReducer";
/**
 * Created by nick on 7/10/2019.
 */
export class Order implements IOrder {
  details: OrderDetail[];
  customerID: number;
  warehouseID: number;
  shipMethodID: number;
  priceTypeID: number;
  overrideShipping: string;
  shippingAmountOverride: number;
  address1: string;
  address2: string;
  city: string;
  country: string;  state: string;
  zip: string;
  dropShipID: number;
  couponCode: string;
  other17: string;
  dropShipDetails: OrderDetail[];
  total: number;
  subTotal: number;
  shipMethods: IShipMethod[]
  constructor() {
    this.total=0;
    this.subTotal=0;
  }
}
