import {IReferrer} from "./interfaces";
/**
 * Created by nick on 7/8/2019.
 */
export class Referrer implements IReferrer {
  referrerID: number;
  referrerName: string;
  firstName: string;
  lastName: string;
}
